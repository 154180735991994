<template>
  <div
    class="tw-items-center tw-justify-between tw-space-y-5 tw-text-center tw-text-sm tw-text-gray-500 lg:tw-flex lg:tw-space-y-0"
  >
    <div>
      <span class="tw-hidden lg:tw-block"
        >© Sproutly Tech Ltd. {{ currentYear }}</span
      >
    </div>

    <!-- Current Version -->
    <div>
      <span>V2.0.1</span>
    </div>

    <div class="tw-flex tw-items-center tw-justify-center tw-space-x-2">
      <AppIcon name="mail-01" size="16px" />
      <span>support@sproutly.africa</span>
    </div>
  </div>
</template>

<script setup lang="ts">
const currentYear = computed(() => {
  return new Date().getFullYear();
});
</script>

<style></style>
